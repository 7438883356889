<template>
    <template v-if="editorSchema.type === 'object'">
        <table ref="tableRef" class="table table-sm table-bordered mb-0 bg-light-subtle" style="outline: none;" tabindex="-1" v-bind="$attrs">
            <thead v-if="!disableFilter">
                <tr>
                    <th class="w-25">
                        <input type="search" v-model="keySearch" class="outline-none w-100 border-0" :placeholder="$t('Search key...')">
                    </th>
                    <th class="w-75">
                        <input type="search" v-model="valueSearch" class="outline-none w-100 border-0" :placeholder="$t('Search value...')">
                    </th>
                </tr>
            </thead>
            <tbody class="overflow-auto" :class="{'table-group-divider': !disableFilter}">
                <template v-if="!invalidInput">
                    <tr v-for="(value, key) in objectValue" v-show="showRow(value, key)">
                        <td class="hover-wrapper position-relative w-25" :title="getSchemaPropertyFromEntry(key, value)?.description">
                            {{ getTitleForKey(key) }}
                            <button v-if="!readonly" class="btn btn-link btn-sm position-absolute show-on-hover how-on-hover" style="top: 2px; right: 2px;"
                                @click="() => { delete objectValue[key]; }">
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </td>
                        <td class="w-75">
                            <JSONEditor v-model:property="objectValue[key]" :schema="getSchemaPropertyFromEntry(key, value)" disableFilter 
                                isInnerEditor :readonly="readonly"/>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tfoot v-if="!readonly && (editorSchema.additionalProperties || missingKeys.length > 0)">
                <tr>
                    <td class="hover-wrapper position-relative w-25">
                        <OAutoComplete class="outline-none w-100 border-0" field="value" :placeholder="$t('Add')" :getData="getAvailableKeys" searchOnFocus
                            :data-bs-title="$t('Key not in allowed list for this schema')" :hideNoResults="!!editorSchema.additionalProperties"
                            :bind="sel => addNewKey(sel.value)" @enter:input="onNewKeyEnter"/>
                    </td>
                    <td class="w-75">
                        <OSelect v-if="isAmbiguous" v-model="ambiguousType" class="outline-none w-100 border-0 focus-skip">
                            <option value="string">
                                {{$t('string')}}
                            </option>
                            <option value="integer">
                                {{$t('integer')}}
                            </option>
                            <option value="boolean">
                                {{$t('boolean')}}
                            </option>
                            <option value="object">
                                {{$t('object')}}
                            </option>
                            <option value="array">
                                {{$t('array')}}
                            </option>
                        </OSelect>
                    </td>
                </tr>
            </tfoot>
        </table>
    </template>
    <template v-else-if="editorSchema.type === 'array'">
        <table ref="tableRef" class="table table-sm table-bordered mb-0 bg-light-subtle" style="outline: none;" tabindex="-1" v-bind="$attrs">
            <tbody class="overflow-auto" :class="{'table-group-divider': !disableFilter}">
                <template v-if="!invalidInput">
                    <tr v-for="(value, index) in objectValue">
                        <td>
                            <JSONEditor v-model:property="objectValue[index]" :schema="getSchemaPropertyFromEntry(index, value)" disableFilter 
                                isInnerEditor :readonly="readonly"/>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tfoot v-if="!readonly && editorSchema.items">
                <tr>
                    <td>
                        <button class="btn btn-sm btn-link w-100 py-0" @click="addNewArrayItem">
                            <i class="bi bi-plus-circle"></i>
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </template>
    <template v-else-if="editorSchema.type === 'integer'">
        <input  type="number" v-model="propertyModel" class="outline-none w-100 border-0" :readonly="readonly" v-bind="$attrs">
    </template>
    <template v-else-if="editorSchema.type === 'boolean'">
        <input type="checkbox" v-model="propertyModel" class="outline-none w-100 border-0" :readonly="readonly" v-bind="$attrs">
    </template>
    <template v-else-if="editorSchema.type === 'string'">
        <input type="text" v-model="propertyModel" class="outline-none w-100 border-0" :readonly="readonly" v-bind="$attrs">
    </template>
<table>

</table>
</template>

<script lang="ts">

type JSONProperty = {
    /** Type of value */
    type: 'object' | 'array' | 'string' | 'integer' | 'boolean',
    /** Used when type is object */
    properties?: Record<string, JSONProperty>,
    /** Used when type is array */
    items?: JSONProperty,
    /** Array of required properties */
    required?: string[],
    /** Will be rendered instead of the key */
    title?: string,
    /** Description that will be rendered as a tooltip for the key */
    description?: string;
    /** Allow values not defined in properties */
    additionalProperties?: boolean | JSONProperty,
};


export default defineComponent({
    name: 'JSONEditor'
});
</script>

<script setup lang="ts">
import type { JSONEditorProps } from 'o365.controls.JSONEditor.ts';
// import OModal from 'o365.vue.components.Modal.vue';
import useDataGridPopupEditor from 'o365.vue.composable.DataGrid.PopupEditor.ts';
import OAutoComplete from 'o365.vue.components.Autocomplete.vue';
import logger from 'o365.modules.Logger.ts';
import OSelect from 'o365.vue.components.Select.vue';
import { ref, computed, watch, defineComponent, onMounted, nextTick } from 'vue';

const props = withDefaults(defineProps<{
    modelValue?: string | object,
    property?: any,
    disableFilter?: boolean,
    schema?: JSONEditorProps,
    isInnerEditor?: boolean,
    readonly?: boolean,
    /** Used when in string output mode. Will format the json string with this space value */
    jsonSpace?: number,
    /** Additional properties type, used when no schema is provided */
    type?: 'string' | 'integer' | 'boolean' | 'array' | 'object'
    /**
     * Used when input is null/undefined to determine if
     * an object or a JSON string should be emitted
     */
    outputType?: 'string' | 'object'
}>(), {
    schema: raw => ({ type: 'object', additionalProperties: raw.type ? { type: raw.type } : true }),
});

const emit = defineEmits<{
    (e: 'update:modelValue', pValue?: string | object ),
    (e: 'update:property', pValue?: any ),
}>();

const editorSchema = computed(() => props.schema);
const keySearch = ref('');
const valueSearch = ref('');
const invalidInput = ref(false);
const tableRef = ref<HTMLElement>(null);

const jsonString = ref('');
const jsonObject = ref<object>(null);

function updateObject() {
    if (typeof jsonString.value === 'string') {
        try {
            const value = JSON.parse(jsonString.value)
            if (typeof value !== 'object') { throw new TypeError('Could not parse JSON string'); }
            jsonObject.value = value;
            invalidInput.value = false;
        } catch (error) {
            invalidInput.value = true;
        }
    } else {
        jsonObject.value = jsonString.value;
        invalidInput.value = false;
    }
};

function updateInput() {
    if (typeof props.modelValue === 'string') {
        jsonString.value = props.modelValue || '';
        updateObject();
    } else if (props.modelValue == null) {
        jsonObject.value = {};
        jsonString.value = '{}';

    } else {
        jsonObject.value = props.modelValue;
        jsonString.value = JSON.stringify(props.modelValue, undefined, props.jsonSpace);
    }
}
let isGridEditor = false; 
if (!props.isInnerEditor) {
    const gridApi = useDataGridPopupEditor({
        focusContainer: tableRef
    });
    isGridEditor = gridApi.isGridEditor;
    watch(() => props.modelValue, () => {
        updateInput();
    });
    watch(jsonString, updateObject);
    watch(jsonObject, () => {
        const emptyValue = jsonObject.value == null || Object.keys(jsonObject.value).length === 0;
        if (typeof props.modelValue === 'string') {
            emit('update:modelValue', emptyValue ? '' : JSON.stringify(jsonObject.value, undefined, props.jsonSpace));
        } else if (props.modelValue) {
            emit('update:modelValue', jsonObject.value);
        } else {
            if (emptyValue) {
                emit('update:modelValue', (props.outputType == null || props.outputType === 'string') ? '' : jsonObject.value);
            } else {
                emit('update:modelValue', (props.outputType == null || props.outputType === 'string') ? JSON.stringify(jsonObject.value, undefined, props.jsonSpace) : jsonObject.value);
            }
        }
    }, { deep: true });
}

const propertyModel = computed({
    get() { return props.property; },
    set(pValue) {
        emit('update:property', pValue);
    }
});

const objectValue = computed<object>(() => {
    return props.property ?? jsonObject.value;
});

const missingKeys = computed(() => {
    if (editorSchema.value.properties == null) { return []; }
    const existingKeys = Object.keys(objectValue.value ?? {});
    return Object.keys(editorSchema.value.properties).filter(key => !existingKeys.includes(key));
});

const isAmbiguous = computed(() => {
    return editorSchema.value.type === 'object' && editorSchema.value.additionalProperties === true;
}); 
const ambiguousType = ref('string');


function showRow(pValue: any, pKey: string) {
    if (!keySearch.value && !valueSearch.value) {
        return true;
    } else {
        let keyMatch = !keySearch.value;
        let valueMatch = !valueSearch.value;
        if (keySearch.value) {
            const searchString = keySearch.value.toLowerCase();
            const key = pKey.toLowerCase();
            keyMatch = key.includes(searchString);
        }
        if (valueSearch.value && typeof pValue === 'string') {
            const searchString = valueSearch.value.toLowerCase();
            const value = pValue.toLowerCase();
            valueMatch = value.includes(searchString);
        }
        return keyMatch && valueMatch;
    }
}

function getTitleForKey(pKey: string) {
    if (editorSchema.value?.properties && editorSchema.value.properties[pKey]) {
        return editorSchema.value.properties[pKey].title ?? pKey;
    } else {
        return pKey;
    }
}


function getSchemaPropertyFromEntry(pProperty: string, pValue?: any) {
    if (editorSchema.value.properties && editorSchema.value.properties[pProperty]) {
        return editorSchema.value.properties[pProperty];
    } else if (pValue !== undefined) {
        return getJSONPropertyFromValue(pValue);
    } else if (editorSchema.value.additionalProperties) {
        if (typeof editorSchema.value.additionalProperties === 'object') {
            return editorSchema.value.additionalProperties;
        } else if (editorSchema.value.additionalProperties === true) {
            switch (ambiguousType.value) {
                case 'object':
                    return { type: 'object', additionalProperties: true };
                default:
                    return { type: ambiguousType.value };
            }
        }
    }
    return { type: 'string' };
}

function getJSONPropertyFromValue(pValue: any): JSONProperty {
    if (pValue == null) {
        return { type: 'string' };
    } else if (typeof pValue === 'number') {
        return { type: 'integer' };
    } else if (Array.isArray(pValue)) {
        return { type: 'array', items: { type: 'string' } };
    } else if (typeof pValue === 'object') {
        return { type: 'object', additionalProperties: true }
    } else {
        return { type: 'string' };
    }
}

function getAvailableKeys(pValue: string) {
    const keys = missingKeys.value;
    const result = keys.filter(x => x.startsWith(pValue)).map(x => ({ value: x}));
    return Promise.resolve(result);
}

let tooltipDebounce: number | null = null;
function onNewKeyEnter(pEvent: KeyboardEvent, pValue: string) {
    if (isGridEditor) {
        pEvent.stopPropagation();
    }
    if (editorSchema.value.additionalProperties == null || editorSchema.value.additionalProperties === false) {
        window.requestAnimationFrame(() => {
            try {
                const inputEl = pEvent.target as HTMLElement;
                const tooltip = window.bootstrap.Tooltip.getOrCreateInstance(inputEl);
                tooltip.enable();
                tooltip.show();
                if (tooltipDebounce) { window.clearTimeout(tooltipDebounce); }
                tooltipDebounce = window.setTimeout(() => {
                    try {
                        tooltip.hide();
                        tooltip.disable();
                        tooltip.dispose();
                    } catch(ex) {
                        logger.error(ex);
                    }
                }, 1500);
            } catch (ex) {
                logger.error(ex);
            }
        });
        return;
    }
    addNewKey(pValue);
}

function addNewArrayItem() {
    if (objectValue.value == null) {
        jsonObject.value = [];
    }
    const schema = editorSchema.value.items;
    switch (schema.type) {
        case 'integer':
            objectValue.value.push(undefined);
            break;
        case 'object':
            objectValue.value.push({});
            break;
        case 'array':
            objectValue.value.push([]);
            break;
        case 'boolean':
            objectValue.value.push(false);
            break;
        default:
            objectValue.value.push('');
            break;
    }
}


function addNewKey(pValue: string) {
    if (!pValue) { 
        return; 
    } 
    if (objectValue.value == null) {
        jsonObject.value = {};
    }
    if (objectValue.value.hasOwnProperty(pValue)) { 
        return;
    } 
    const schema = getSchemaPropertyFromEntry(pValue);
    switch (schema.type) {
        case 'integer':
            objectValue.value[pValue] = undefined;
            break;
        case 'object':
            objectValue.value[pValue] = {};
            break;
        case 'array':
            objectValue.value[pValue] = [];
            break;
        case 'boolean':
             objectValue.value[pValue] = false;
             break;
        default:
            objectValue.value[pValue] = '';
            break;
    }
    // nextTick().then(() => {
        // const focusables = Array.from(tableRef.value.querySelector('tbody').querySelectorAll('input:not(.focus-skip)'));
        // focusables.at(-1).focus();
    // });
}

onMounted(() => {
    if (!props.isInnerEditor) {
        updateInput();
    }
});
</script>

<style scoped>

.outline-none, table :deep(.outline-none) {
    /* outline: none; */
    outline-color: var(--bs-gray-400)
}
</style>